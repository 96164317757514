class FooterLegacy {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'footerLvl2LinkClicked': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.linkLabel = this.data.attributes.linkLabel;
        googleFormattedData.linkUrl = this.data.attributes.linkUrl;

        break;
      }

      case 'footerLvl3LinkClicked': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.linkLabel = this.data.attributes.linkLabel;
        googleFormattedData.linkUrl = this.data.attributes.linkUrl;

        break;
      }

      case 'footerAncLinkClicked': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.linkLabel = this.data.attributes.linkLabel;
        googleFormattedData.linkUrl = this.data.attributes.linkUrl;

        break;
      }

      case 'footerSocialClick': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.linkUrl = this.data.attributes.socialUrl;

        break;
      }

      case 'footerCountryChanged': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.country = this.data.attributes.country;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default FooterLegacy;
