import RefineSection from './refine-section';
import LandingPageContent from './landing-page-content';
import SearchPagination from './search-pagination';
import SearchResultsList from './search-results-list';
import SearchBox from './search-box';

class IPHBaseHandler {
  constructor(component, event, data) {
    this.component = component;
    this.event = event;
    this.data = data;
  }

  translate() {
    let translatedDataObject = {};
    switch (this.component) {
      case 'refineSection': {
        const refineSection = new RefineSection(this.event, this.data);
        translatedDataObject = {
          google: refineSection.googleFormat(),
        };
        break;
      }
      case 'searchBox': {
        const searchBox = new SearchBox(this.event, this.data);
        translatedDataObject = {
          google: searchBox.googleFormat(),
        };
        break;
      }
      case 'landingPageContent': {
        const landingPageContent = new LandingPageContent(this.event, this.data);
        translatedDataObject = {
          google: landingPageContent.googleFormat(),
        };
        break;
      }
      case 'searchPagination': {
        const searchPagination = new SearchPagination(this.event, this.data);
        translatedDataObject = {
          google: searchPagination.googleFormat(),
        };
        break;
      }
      case 'searchResultsList': {
        const searchResultsList = new SearchResultsList(this.event, this.data);
        translatedDataObject = {
          google: searchResultsList.googleFormat(),
        };
        break;
      }

      default:
        throw Error(`Component ${this.component} is not registered`);
    }
    return translatedDataObject;
  }
}

export default IPHBaseHandler;
