class LandingPageContent {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};

    switch (this.event) {
      case 'accordion': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.index = this.data.attributes.index;
        googleFormattedData.title = this.data.attributes.title;
        googleFormattedData.componentClass = this.data.attributes.componentClass;
        googleFormattedData.numOfItems = this.data.attributes.numOfItems;
        googleFormattedData._sourceElement = this.data.attributes._sourceElement;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }

    return googleFormattedData;
  }
}

export default LandingPageContent;
