/* global Foundation, jQuery, window */

import Typed from 'typed.js';

(function myobTypedFactory($, window, Foundation) {
  class MyobTyped {
    constructor(element, options) {
      this.$element = element;
      this.options = $.extend({}, MyobTyped.defaults, this.$element.data(), options);

      this._init();
      Foundation.registerPlugin(this, 'MyobTyped');
    }

    _init() {
      if (this.$element.length > 0) {
        const typedContainer = this.$element.find('.typed__text');
        const typedMessages = this.$element.find('.typed__strings');

        const typedOptions = {
          stringsElement: typedMessages.get(0),
          backDelay: this.options.backDelay,
          backSpeed: this.options.backSpeed,
          typeSpeed: this.options.typeSpeed,
          loop: this.options.typeSpeed,
        };

        this.typed = new Typed(typedContainer.get(0), typedOptions);
      }
    }

    destory() {
      this.typed = null;
    }
  }

  MyobTyped.defaults = {
    backDelay: 1300,
    backSpeed: 20,
    typeSpeed: 40,
    loop: true,
  };

  Foundation.plugin(MyobTyped, 'MyobTyped');
}(jQuery, window, Foundation));
