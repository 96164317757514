import AEMBaseHandler from './translate/aem/aem-base-handler';
import IPHBaseHandler from './translate/iph/iph-base-handler';

import PushData from './push-data';

class AnalyticsHelper {
  constructor() {
    this.pushData = new PushData();
  }

  setWindow(windowObj) {
    this.pushData.setWindow(windowObj);
  }

  pushTranslatedData(translatedData) {
    if (translatedData.google) {
      this.pushData.pushToGoogle(translatedData.google);
    }
  }

  static refineFormId(formId) {
    return formId.replace('-pardot-psuedo', '');
  }

  record(eventInfo, data) {
    let eventInfoArray;
    if (eventInfo.indexOf(':') > -1) {
      eventInfoArray = eventInfo.split(':');
    }

    if (eventInfoArray && eventInfoArray.length === 3) {
      const project = eventInfoArray[0];
      const component = eventInfoArray[1];
      const event = eventInfoArray[2];

      switch (project) {
        case 'AEM': {
          const aemBaseFormat = new AEMBaseHandler(component, event, data);
          const translatedData = aemBaseFormat.translate();
          this.pushTranslatedData(translatedData);
          break;
        }
        case 'IPH': {
          const iphBaseFormat = new IPHBaseHandler(component, event, data);
          const translatedData = iphBaseFormat.translate();
          this.pushTranslatedData(translatedData);
          break;
        }

        default:
          throw Error(`Project ${project} is not registered`);
      }
    }
  }
}

export default AnalyticsHelper;
