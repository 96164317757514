/* global window, $ */

class MyobNavigation {
  constructor() {
    this.$element = $('.header-mega-menu');

    if (this.$element && this.$element.length > 0) {
      this._init();
    }
  }

  _init() {
    this.$headerNavigation = $('.header__navigation');
    this.$overlay = this.$element.find('.header__overlay');
    this.$hamburgerTrigger = this.$element.find('[data-hamburger-trigger]');
    this.$breadcrumb = this.$element.find('[data-navigation-breadcrumb]');
    this.$dropdownArea = this.$element.find('[data-dropdown-area]');
    this.$dropdownTrigger = this.$element.find('[data-navigation-trigger]');
    this.$searchTrigger = this.$element.find('[data-search-trigger]');
    this.$searchContent = this.$element.find('[data-search-content]');
    this.$searchInput = this.$searchContent.find('input[type="text"]');
    this.$searchButton = this.$searchContent.find('button[type="submit"]');
    this.loadedOnMobile = false;
    this.elementBottom = MyobNavigation.getOffset(this.$element[0]);

    // body overflow is visible on load so toggleBodyOverflow will work as expected */
    document.body.style.overflow = 'visible';
    this._events();
  }

  /* TODO: For Animating background height */
  // calculateDropdownAreaHeight($target) {
  //   const parentHeight = $target.parents('.header__panel').attr('data-navigation-height');
  //   let dropdownHeight = $target.height();
  //   $target.attr('data-navigation-height', dropdownHeight);
  //   if (parentHeight && dropdownHeight <= parentHeight) {
  //     dropdownHeight = parentHeight;
  //   }
  //   this.$dropdownArea.css({
  //     height: dropdownHeight
  //   });
  // }

  checkAndHideActive() {
    this.$element.find('.is-active').removeClass('is-active');

    if (this.$element.hasClass('search-open')) {
      this.$element.removeClass('search-open');
    } else if (this.$element.hasClass('menu-open')) {
      this.$element.removeClass('menu-open');
    }
  }

  static getOffset(el) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.bottom + scrollTop;
  }

  static whichAnimationEvent(element) {
    const animations = {
      animation: 'animationend',
      OAnimation: 'oAnimationEnd',
      MozAnimation: 'animationend',
      WebkitAnimation: 'webkitAnimationEnd',
    };

    const animation = Object.entries(animations).find(
      entry => element.style[entry[0]] !== undefined
    );
    return animation[1];
  }

  static toggleBodyOverflow(overflowValue) {
    return overflowValue === 'visible' ? 'hidden' : 'visible';
  }

  static toggleActive($trigger, $target, $container, area) {
    $trigger.toggleClass('is-active').siblings().removeClass('is-active is-animating');
    $target.addClass('is-animating').siblings().removeClass('is-active is-animating');

    const isFirstLevel = $trigger.closest('nav').hasClass('navigation--level-1');

    if ($container && isFirstLevel) {
      $target.find('.is-active').removeClass('is-active');
      $container.addClass(`${area}-animating`);
    }

    $target.one(MyobNavigation.whichAnimationEvent($target[0]), () => {
      $target.removeClass('is-animating');
      if ($container && isFirstLevel) {
        $container.removeClass(`${area}-animating`);
      }

      if ($target.hasClass('is-active')) {
        $target.removeClass('is-active');
        if ($container && isFirstLevel) {
          $container.removeClass(`${area}-open`);
        }
      } else {
        $target.addClass('is-active');
        if ($container && isFirstLevel) {
          $container.addClass(`${area}-open`);
        }
      }
    });
  }

  static toggleActiveChildren($container) {
    $container.find('.navigation__link.is-active').each((idx, ele) => {
      const $childTrigger = $container.find(`[data-navigation-trigger="${$(ele).data('navigationTrigger')}"]`);
      const $childTarget = $container.find(`[data-navigation-content="${$(ele).data('navigationTrigger')}"]`);

      MyobNavigation.toggleActive($childTrigger, $childTarget);
    });
  }

  _events() {
    this.$overlay.on('click', () => {
      this.checkAndHideActive();
    });

    this.$hamburgerTrigger.on('click', (event) => {
      const $target = this.$element.find(`[data-hamburger-content="${$(event.currentTarget).data('hamburger-trigger')}"]`);
      document.body.style.overflow = MyobNavigation.toggleBodyOverflow(document.body.style.overflow);
      MyobNavigation.toggleActiveChildren(this.$element);
      MyobNavigation.toggleActive($(event.currentTarget), $target, this.$element, 'menu');
    });

    this.$breadcrumb.on('click', (event) => {
      event.preventDefault();
      const $trigger = this.$element.find(`[data-navigation-trigger="${$(event.currentTarget).data('navigation-breadcrumb')}"]`);
      const $target = this.$element.find(`[data-navigation-content="${$(event.currentTarget).data('navigation-breadcrumb')}"]`);

      MyobNavigation.toggleActiveChildren($target);
      MyobNavigation.toggleActive($trigger, $target, this.$element, 'menu');
    });

    this.$dropdownTrigger.on('click', (event) => {
      event.preventDefault();
      const $target = this.$element.find(`[data-navigation-content="${$(event.currentTarget).data('navigation-trigger')}"]`);

      // this.calculateDropdownAreaHeight($target);
      MyobNavigation.toggleActive($(event.currentTarget), $target, this.$element, 'menu');
    });

    this.$searchTrigger.on('click', (event) => {
      event.preventDefault();

      if (this.$searchContent.is(':hidden')) {
        this.checkAndHideActive();
      }

      MyobNavigation.toggleActive($(event.currentTarget), this.$searchContent, this.$element, 'search');
      this.$searchInput.focus();
    });

    this.$searchInput.on('keyup', () => {
      if (this.$searchInput.val()) {
        this.$searchButton.removeAttr('disabled');
      } else {
        this.$searchButton.attr('disabled', 'disabled');
      }
    });

    ['load', 'resize'].forEach((event) => {
      window.addEventListener(event, () => {
        const mediumScreenWidth = 768;
        const DOMChange = window.Site.debounce(() => {
          if (window.innerWidth >= mediumScreenWidth) {
            document.body.style.overflow = 'visible';
            this.$headerNavigation[0].style.top = 'auto';
            Array.prototype.forEach.call(this.$dropdownArea[0].children, (el) => {
              const navigationPanels = el;
              navigationPanels.style.top = 'auto';
              navigationPanels.style.height = 'auto';
            });

            return;
          }

          if (window.innerWidth < mediumScreenWidth) {
            // don't trigger hide active if event is resize when already loaded for mobile
            // beacuse this is closing the navigation menu on keyboard input
            // for andrioid phones
            if (event === 'load') {
              if ((!this.loadedOnMobile) && (window.innerWidth < mediumScreenWidth)) {
                this.loadedOnMobile = true;
              }
            }
            if (!this.loadedOnMobile) {
              this.checkAndHideActive();
            }
            if (window.pageYOffset <= this.elementBottom) {
              const elementBottom = this.$element[0].getBoundingClientRect().bottom;
              this.$headerNavigation[0].style.top = `${elementBottom}px`;

              Array.prototype.forEach.call(this.$dropdownArea[0].children, (el) => {
                const navigationPanels = el;
                navigationPanels.style.top = `${elementBottom}px`;
                navigationPanels.style.height = `calc(100% - ${elementBottom}px)`;
              });
            } else {
              this.$headerNavigation[0].style.top = 'auto';

              Array.prototype.forEach.call(this.$dropdownArea[0].children, (el) => {
                const navigationPanels = el;
                navigationPanels.style.top = 'auto';
                navigationPanels.style.height = 'auto';
              });
            }
          }
        }, 250);

        DOMChange();
      });
    });
  }
}

export default new MyobNavigation();
