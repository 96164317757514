class Video {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'autoPlay': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'play': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'pause': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'resume': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'twentyFivePercent': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'fiftyPercent': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'seventyFivePercent': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      case 'hundredPercent': {
        [, googleFormattedData.event] = this.data.eventInfo.type.split('.');
        googleFormattedData.category = this.data.category.primaryCategory;
        googleFormattedData.label = this.data.attributes.title;
        googleFormattedData.videoTitle = this.data.attributes.title;
        googleFormattedData.videoID = this.data.attributes.videoID;
        googleFormattedData.videoDuration = this.data.attributes.length;
        googleFormattedData.videoAction = this.data.attributes.videoAction;
        googleFormattedData.videoCurrentTime = this.data.attributes.currentTime;
        googleFormattedData.videoPercent = this.data.attributes.percent;
        googleFormattedData.videoPlayer = this.data.attributes.source;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default Video;
