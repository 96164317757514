class NavigationLegacy {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};
    switch (this.event) {
      case 'navigationItemClick': {
        googleFormattedData.event = 'navClick';
        googleFormattedData.clickTimeStamp = this.data.eventInfo.timeStamp;
        googleFormattedData.navPath = this.data.attributes.navPath;
        googleFormattedData.navText = this.data.eventInfo.eventName;
        googleFormattedData.navType = this.data.attributes.navType;
        googleFormattedData.targetUrl = this.data.attributes.targetUrl;

        break;
      }

      case 'navigationItemHover': {
        googleFormattedData.event = 'navHover';
        googleFormattedData.hoverTimeStamp = this.data.eventInfo.timeStamp;
        googleFormattedData.navType = this.data.attributes.navType;

        break;
      }

      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default NavigationLegacy;
