/* global Foundation, jQuery, window */

import 'foundation-sites/js/foundation.util.keyboard';
import 'foundation-sites/js/foundation.util.timerAndImageLoader';
import 'foundation-sites/js/foundation.util.motion';
import 'foundation-sites/js/foundation.tabs';
import AnalyticsHelper from '../../../assets/js/helpers/analytics/analytics-helper';

(function myobTabsFactory($, Foundation) {
  const tabIds = [];
  class MyobTabs extends Foundation.Tabs {
    constructor(element, options) {
      const overrideOptions = $.extend(options, MyobTabs.defaults);

      super(element, overrideOptions);
      this.options = overrideOptions;

      Foundation.registerPlugin(this, 'MyobTabs');
    }

    _init() {
      this.$tabsMenu = this.$element.find('.tabs__menu');
      this.$tabsList = this.$element.find('.tabs__list');

      this.$element.find('li a').each((idx, ele) => {
        tabIds.push($(ele).attr('id'));
      });

      this.$tabsMenu[0].setAttribute('data-overflowing', MyobTabs.determineOverflow(this.$tabsList, this.$tabsMenu));

      this.analyticsHelper = new AnalyticsHelper();

      super._init();
    }

    _events() {
      super._events();

      // Tab changed
      this.$element.on('change.zf.tabs', (ele, target) => {
        const currentTabId = $(target)[0].lastElementChild.id;
        const currentTabIdIndex = tabIds.indexOf(currentTabId);
        let currentTabLabel;

        if (currentTabIdIndex > -1) {
          currentTabLabel = this.$element.find(`#${currentTabId}`).text();
        }

        this.analyticsHelper.record('AEM:tabs:tabChange', {
          eventInfo: {
            eventName: 'tabChange',
          },
          attributes: {
            tabIndex: tabIds.indexOf(currentTabId) + 1,
            tabLabel: currentTabLabel,
            componentClass: this.options.componentClass,
            numOfItems: tabIds.length,
            _sourceElement: $(target)[0].lastElementChild,
          },
        });
      });

      this.$tabsMenu.on('scroll', () => {
        this.$tabsMenu[0].setAttribute('data-overflowing', MyobTabs.determineOverflow(this.$tabsList, this.$tabsMenu));
      });

      window.addEventListener('resize', () => {
        const DOMChange = window.Site.debounce(() => {
          this.$tabsMenu[0].setAttribute('data-overflowing', MyobTabs.determineOverflow(this.$tabsList, this.$tabsMenu));
        }, 250);
        DOMChange();
      });
    }

    static determineOverflow(content, container) {
      if (content[0] && container[0]) {
        const containerMetrics = container[0].getBoundingClientRect();
        const containerMetricsRight = Math.floor(containerMetrics.right);
        const containerMetricsLeft = Math.floor(containerMetrics.left);
        const contentMetrics = content[0].getBoundingClientRect();
        const contentMetricsRight = Math.floor(contentMetrics.right);
        const contentMetricsLeft = Math.floor(contentMetrics.left);

        if (containerMetricsLeft > contentMetricsLeft
          && containerMetricsRight < contentMetricsRight) {
          return 'both';
        } if (contentMetricsLeft < containerMetricsLeft) {
          return 'left';
        } if (contentMetricsRight > containerMetricsRight) {
          return 'right';
        }
      }
      return 'none';
    }
  }

  MyobTabs.defaults = {
    linkClass: 'tabs__title',
    linkActiveClass: 'is-active',
    panelClass: 'tabs__panel',
    panelActiveClass: 'is-active',
    componentClass: 'tabs',
    slideSpeed: 250,
  };

  Foundation.plugin(MyobTabs, 'MyobTabs');
}(jQuery, Foundation));
