/* global $, window */

class CSRFHelper {
  constructor() {
    this.csrfCookieName = 'XSRF-TOKEN';
    this.csrfCookieUrl = '/libs/granite/csrf/token.json';
    this.aemServletPaths = ['/bin/myob'];
  }

  static isSameOrigin(url) {
    // url could be relative or scheme relative or absolute
    const { host } = window.location; // host + port
    const { protocol } = window.location;
    const relativeOrigin = `//${host}`;
    const origin = protocol + relativeOrigin;

    // Allow absolute or scheme relative URLs to same origin
    return (
      url === origin
      || url.slice(0, origin.length + 1) === `${origin}/`
      || (url === relativeOrigin
      || url.slice(0, relativeOrigin.length + 1) === `${relativeOrigin}/`)
      // or any other URL that isn't scheme relative or absolute i.e relative.
      || !/^(\/\/|http:|https:).*/.test(url)
    );
  }

  isCSRFProtectedUrl(url) {
    let protectedUrl = false;
    let i = 0;
    for (i; i < this.aemServletPaths.length; i += 1) {
      if (url && url.indexOf(this.aemServletPaths[i]) > -1 && CSRFHelper.isSameOrigin(url)) {
        protectedUrl = true;
      }
    }
    return protectedUrl;
  }

  ajaxGetToken(formAction) {
    return new Promise((resolve) => {
      if (this.isCSRFProtectedUrl(formAction)) {
        $.ajax({
          type: 'GET',
          url: this.csrfCookieUrl,
          dataType: 'json',
        })
          .done((success) => {
            resolve(success.token);
          })
          .fail(() => {
            resolve(null);
          });
      } else {
        resolve(null);
      }
    });
  }
}

export default CSRFHelper;
