/* global jQuery:false, window:false */

import queryString from 'qs';
import Cookies from 'js-cookie';
import AnalyticsHelper from '../helpers/analytics/analytics-helper';
import setupSVGs from './svg';

window.Site = (function globalVars($, window) {
  const minSmallScreen = 767;
  const queryWithoutQuestionMark = window.location.search.substr(1);
  const queryStringParams = queryString.parse(queryWithoutQuestionMark);

  const analyticsHelper = new AnalyticsHelper();
  const formCookie = 'form-submission-id';
  const cookieFormId = Cookies.get(formCookie);
  let formSuccessId = null;

  function getUrlParameter(sParam) {
    return queryStringParams[sParam];
  }

  function debounce(func, wait, immediate, args) {
    let timeout;
    return () => {
      const context = this;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  // @see http://digitalize.ca/2011/02/javascript-tip-bust-and-disable-console-log/
  // @see https://stackoverflow.com/questions/7089443/restoring-console-log
  function turnOffConsole() {
    function clobberConsole() {
      const clobber = ['assert', 'clear', 'context', 'count', 'debug', 'dir',
        'dirxml', 'error', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'timeline', 'timelineEnd', 'trace', 'warn'];

      for (let i = 0; i < clobber.length; i += 1) {
        window.console[clobber[i]] = function noOp() {}; // eslint-disable-line no-param-reassign
      }
    }

    if (getUrlParameter('console') !== 'true'
    && ['ERROR', 'WARN', 'INFO', 'DEBUG', 'ALL'].indexOf(getUrlParameter('optimizely_log')) === -1) {
      window.console = window.console || {}; // eslint-disable-line no-param-reassign
      clobberConsole();
    }
  }

  if (typeof cookieFormId !== 'undefined') {
    // Trigger the Analytics formsuccess event
    const analyticsFormId = AnalyticsHelper.refineFormId(cookieFormId);
    analyticsHelper.record('AEM:form:formSuccess', {
      eventInfo: {
        eventName: 'genericFormSuccess',
      },
      attributes: {
        formId: analyticsFormId,
        analyticsFormId,
      },
    });
    Cookies.remove(formCookie);
    formSuccessId = cookieFormId;
  }

  return {
    minSmallScreen,
    formSuccessId,
    debounce,
    getUrlParameter,
    turnOffConsole
  };
}(jQuery, window));

// Add polyfill and set up gradients
setupSVGs();
