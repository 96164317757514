class RefineSection {
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }

  googleFormat() {
    const googleFormattedData = {};

    switch (this.event) {
      case 'facet': {
        googleFormattedData.event = this.data.eventInfo.eventName;
        googleFormattedData.action = this.data.eventInfo.eventAction;
        googleFormattedData.pageNo = this.data.attributes.pageNo;
        googleFormattedData.resultsPerPage = this.data.attributes.resultsPerPage;
        googleFormattedData.resultCount = this.data.attributes.resultCount;
        googleFormattedData.category = this.data.attributes.category;
        googleFormattedData.searchTerm = this.data.attributes.searchTerm;
        googleFormattedData.product = this.data.attributes.product;
        googleFormattedData.facet = this.data.attributes.facet;

        break;
      }
      default:
        throw Error(`Event ${this.event} is not registered`);
    }
    return googleFormattedData;
  }
}

export default RefineSection;
