/* global window */

/*
* Tootltip function
*/

import { registerPluginNames } from '../../assets/js/registrar';

const defaults = {
  tooltipText: 'tooltip',
  tooltipDirection: 'bottom',
  tooltipHideForTouchScreen: false
};

let toolTipDiv = null;
let currentElement = null;
const toolTipClassName = 'tooltip';
let userIsTouching = false;

/**
 *  Used to create the tooltip dom element
 */
const createTooltip = (elementDefaults) => {
  toolTipDiv = document.createElement('div');
  toolTipDiv.className = toolTipClassName;
  toolTipDiv.innerText = elementDefaults.tooltipText;
  toolTipDiv.setAttribute('direction', elementDefaults.tooltipDirection);
  return toolTipDiv;
};

/**
 *  Set the coordinates for the tooltip based on the trigger
 */
const setCoordinates = (element) => {
  const elemRect = element.getBoundingClientRect();
  const toolTipRect = toolTipDiv.getBoundingClientRect();

  let xTranslate = null;
  let yTranslate = null;

  const toolTipArrowWidth = 6;

  switch (toolTipDiv.getAttribute('direction')) {
    case ('top'):
      yTranslate = Math.floor((elemRect.top - toolTipRect.height)
      - (toolTipRect.top + toolTipArrowWidth));
      xTranslate = Math.floor((elemRect.left + (elemRect.width / 2))
      - (toolTipRect.width / 2));
      break;
    case ('left'):
      yTranslate = Math.floor(((elemRect.top + (elemRect.height / 2))
      - (toolTipRect.height / 2)) - toolTipRect.top);
      xTranslate = Math.floor(elemRect.left - (toolTipRect.width + toolTipArrowWidth));
      break;
    case ('right'):
      yTranslate = Math.floor(((elemRect.top + (elemRect.height / 2))
      - (toolTipRect.height / 2)) - toolTipRect.top);
      xTranslate = Math.floor(elemRect.left + elemRect.width + toolTipArrowWidth);
      break;
    default:
      yTranslate = Math.floor((elemRect.top + elemRect.height)
      - (toolTipRect.top - toolTipArrowWidth));
      xTranslate = Math.floor((elemRect.left + (elemRect.width / 2))
      - (toolTipRect.width / 2));
  }

  toolTipDiv.style.WebkitTransform = `translate(${xTranslate}px, ${yTranslate}px)`;
  toolTipDiv.style.msTransform = `translate(${xTranslate}px, ${yTranslate}px)`;
  toolTipDiv.style.transform = `translate(${xTranslate}px, ${yTranslate}px)`;
};

const hide = () => {
  if (toolTipDiv) {
    document.body.removeChild(toolTipDiv);
    currentElement = null;
    toolTipDiv = null;
  }
};

const show = (element) => {
  if ((currentElement) && (currentElement !== element)) {
    hide();
  }
  toolTipDiv = createTooltip(element.defaults);
  document.body.appendChild(toolTipDiv);
  setCoordinates(element.triggers);
  currentElement = element.triggers;
};

/**
 *  Hide the tooltip if click outside the trigger
 */
const checkForOutsideClick = (event) => {
  if ((currentElement) && (!currentElement.contains(event.target))) {
    hide();
  }
};

const addEventListeners = (elementData, action) => {
  if (action === 'hover') {
    Array.from(elementData).forEach((element) => {
      element.triggers.addEventListener('mouseover', (event) => {
        if (userIsTouching) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          show(element);
        }
      });
      element.triggers.addEventListener('mouseleave', hide.bind(this, element));
    });
  }

  if (action === 'click') {
    Array.from(elementData).forEach((element) => {
      element.triggers.addEventListener('click', () => {
        if (!element.defaults.tooltipHideForTouchScreen) {
          show(element);
        }
      });
    });
    document.addEventListener('touchstart', event => checkForOutsideClick(event));
  }
};

const getDefaults = (elemDataSet) => {
  const newDataSet = {};
  Object.keys(elemDataSet).forEach((key) => {
    if (elemDataSet[key] !== null) {
      newDataSet[key] = elemDataSet[key];
    }
  });
  const originalDataSet = Object.assign({}, defaults);
  return Object.assign(originalDataSet, newDataSet);
};


const constructElementDataSet = (element) => {
  const tooltipText = element.getAttribute('data-tooltip-text');
  const tooltipDirection = element.getAttribute('data-tooltip-direction');
  const tooltipHideForTouchScreen = element.hasAttribute('data-tooltip-hide-for-touchscreen');
  return { tooltipText, tooltipDirection, tooltipHideForTouchScreen };
};

const init = (elements) => {
  const elementData = Array.from(elements).map(element => ({
    triggers: element,
    defaults: getDefaults(constructElementDataSet(element))
  }));

  addEventListeners(elementData, 'hover');
  // Check if user is touching screen, credit: https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
  window.addEventListener('touchstart', function onFirstTouch() {
    addEventListeners(elementData, 'click');
    userIsTouching = true;
    window.removeEventListener('touchstart', onFirstTouch, false);
  }, false);
};
export default init;

registerPluginNames(init, 'tooltip');
